import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mt-tooltip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tooltip.ui-component.html',
  styleUrl: './tooltip.ui-component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TooltipUiComponent {
  @Input() content = '';
}
