/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ViewChild,
  ViewContainerRef,
  RendererFactory2,
  Input,
  Renderer2,
  ComponentRef,
  forwardRef,
  Provider,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { InputUiComponent } from '../input/input.ui-component';
import { ButtonUiComponent } from '../../button/button.ui-component';

const VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => DynamicInputUiComponent),
  multi: true,
};

@Component({
  selector: 'mt-dynamic-input',
  standalone: true,
  imports: [CommonModule, ButtonUiComponent, InputUiComponent, FormsModule],
  templateUrl: './dynamic-input.ui-component.html',
  styleUrls: ['./dynamic-input.ui-component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicInputUiComponent),
      multi: true,
    },
    VALIDATOR,
  ],
})
export class DynamicInputUiComponent
  implements ControlValueAccessor, Validator
{
  @ViewChild('container', { read: ViewContainerRef })
  viewContainerRef!: ViewContainerRef;

  @Input() label = '';

  @Input() required = false;
  inputs: string[] = [];

  private renderer: Renderer2;

  mainInput = '';

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  private onChange: (_: any) => void = () => {};

  private onTouched: () => void = () => {};
  private componentRefs: ComponentRef<InputUiComponent>[] = [];

  // TODO: Fix validation
  validate(): ValidationErrors | null {
    let isAtLeastOneFilled = false;

    if (this.mainInput) {
      isAtLeastOneFilled = true;
    }

    if (this.mainInput === '') {
      this.componentRefs.forEach((ref) => {
        if (ref.instance.innerValue && ref.instance.innerValue.trim() !== '') {
          return (isAtLeastOneFilled = true);
        }

        return (isAtLeastOneFilled = false);
      });
    }

    if (this.required && !isAtLeastOneFilled) {
      return { required: true };
    }
    return null;
  }

  writeValue(values: string[]): void {
    this.viewContainerRef.clear();
    this.inputs = values || [];
    this.inputs.forEach((value) => this.addInput(value));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onMainInputChange() {
    this.inputs[0] = this.mainInput;
  }

  addInput(value = '') {
    const inputComponentRef =
      this.viewContainerRef.createComponent(InputUiComponent);
    inputComponentRef.instance.label = this.label;
    inputComponentRef.instance.writeValue(value);
    this.componentRefs.push(inputComponentRef);

    const index = this.inputs.length;
    inputComponentRef.instance.registerOnChange((newValue: string) => {
      this.inputs[index] = newValue;
      this.onChange(this.inputs);
      this.onTouched();
    });

    this.inputs.push(value);
    this.createButtons(inputComponentRef);
  }

  createButtons(inputComponentRef: any) {
    const addButtonComponentRef =
      this.viewContainerRef.createComponent(ButtonUiComponent);
    addButtonComponentRef.instance.text = '+';

    addButtonComponentRef.instance.submitEvent.subscribe(() => {
      this.addInput();
    });

    const removeButtonComponentRef =
      this.viewContainerRef.createComponent(ButtonUiComponent);
    removeButtonComponentRef.instance.buttonStyle = 'secondary';
    removeButtonComponentRef.instance.text = '-';

    removeButtonComponentRef.instance.submitEvent.subscribe(() => {
      const inputIndex = this.viewContainerRef.indexOf(
        inputComponentRef.hostView,
      );

      if (inputIndex === 0) {
        this.inputs.splice(1, 1);
      } else {
        this.inputs.splice(inputIndex / 3 + 1, 1);
      }

      this.viewContainerRef.remove(inputIndex);
      this.viewContainerRef.remove(
        this.viewContainerRef.indexOf(addButtonComponentRef.hostView),
      );
      this.viewContainerRef.remove(
        this.viewContainerRef.indexOf(removeButtonComponentRef.hostView),
      );
      this.onChange(this.inputs);
    });
  }
}
