/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  forwardRef,
  Input,
  Provider,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TooltipUiComponent } from '../../tooltip/tooltip.ui-component';

export const INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputUiComponent),
  multi: true,
};

@Component({
  selector: 'mt-input',
  standalone: true,
  imports: [CommonModule, FormsModule, TooltipUiComponent],
  templateUrl: './input.ui-component.html',
  styleUrl: './input.ui-component.scss',
  providers: [INPUT_CONTROL_VALUE_ACCESSOR],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InputUiComponent implements ControlValueAccessor {
  @Input() name = '';
  @Input() placeholder = '';
  @Input() model = '';
  @Input() required = false;

  @Input() tooltipText = '';

  @Input() label = '';

  @Input() errorMessage: string | null = null;

  @Input() maxlength = 500;

  @Input() minlength = 0;
  private _innerValue = '';

  public get innerValue(): string {
    return this._innerValue;
  }

  public set innerValue(newValue: string) {
    this._innerValue = newValue;
    this.onChangeCallback(newValue);
  }

  public registerOnChange(callback: (_: string) => void) {
    this.onChangeCallback = callback;
  }

  public registerOnTouched(callback: () => void) {
    this.onTouchedCallback = callback;
  }

  writeValue(value: string): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  public onBlur() {
    this.onTouchedCallback();
  }

  private onChangeCallback: (_: string) => void = () => {};

  private onTouchedCallback: () => void = () => {};
}
