<button
  (click)="onSubmit($event)"
  [type]="buttonType"
  [class.outline]="outline"
  [class]="buttonStyle"
  [disabled]="disabled"
>
  @if (text) {
    {{ text }}
  } @else {
    <ng-content select="[icon]"></ng-content>
    <ng-content></ng-content>
  }
</button>
