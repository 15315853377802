import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

interface Step {
  title: string;
}

@Component({
  selector: 'mt-steps',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './steps.ui-component.html',
  styleUrl: './steps.ui-component.scss',
})
export class StepsUiComponent {
  @Input() steps!: Step[];
  @Input() currentStep = 0;
}
