import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mt-tag',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tag.ui-component.html',
  styleUrl: './tag.ui-component.scss',
})
export class TagUiComponent {}
