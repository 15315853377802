@if (isLoading) {
  <mt-loader />
}

@if (previousPageUrl) {
  <a [routerLink]="previousPageUrl" routerLinkActive="active">
    <img src="/assets/icons/chevron-circle.svg" alt="true" />
    <span> {{ navigationText }}</span>
  </a>
}

<div class="title">
  <h1>{{ title }}</h1>
  <ng-content select="[actions]"></ng-content>
</div>

<ng-content></ng-content>
