@if (file.url) {
  <img [src]="file.url" alt="asset preview" />
}

<ul class="file-list">
  <li class="file-type">{{ file.type }}</li>

  <li class="file-name">
    {{ file.name }}
  </li>
  @if (file.extension) {
    <li class="file-info">
      {{ file.extension }},
      {{ file.size }}
    </li>
  }
</ul>
