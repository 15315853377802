<mt-button [disabled]="pagination().currentPage === firstPage()" (submitEvent)="onPrevPage()">
  <mt-icon-chevron icon></mt-icon-chevron>
</mt-button>

<ul>
  <li *ngIf="viewAblePagination().isFirstPageVisible">
    <mt-button
      (submitEvent)="onSelectPage(firstPage())"
      [class.active]="pagination().currentPage === firstPage()"
    >
      {{ firstPage() + 1 }}
    </mt-button>
  </li>

  <li *ngIf="viewAblePagination().showStartEllipsis" class="ellipsis">...</li>
  <li *ngFor="let item of viewAblePagination().range; trackBy: trackByFn">
    <mt-button
      (submitEvent)="onSelectPage(item)"
      [class.active]="pagination().currentPage === item"
    >
      {{ item + 1 }}
    </mt-button>
  </li>
  <li *ngIf="viewAblePagination().showEndEllipsis" class="ellipsis">...</li>
  <li *ngIf="viewAblePagination().isLastPageVisible && pagination().totalPages > 0">
    <mt-button
      (submitEvent)="onSelectPage(lastPage())"
      [class.active]="pagination().currentPage === lastPage()"
    >
      {{ lastPage() + 1 }}
    </mt-button>
  </li>
</ul>

<mt-button [disabled]="pagination().currentPage === lastPage()" (submitEvent)="onNextPage()">
  <mt-icon-chevron class="next" icon></mt-icon-chevron>
</mt-button>
