import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderUiComponent } from '../loader/loader.ui-component';
import { ButtonUiComponent } from '../button/button.ui-component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'mt-layout',
  standalone: true,
  imports: [CommonModule, LoaderUiComponent, ButtonUiComponent, RouterLink],
  templateUrl: './layout.ui-component.html',
  styleUrl: './layout.ui-component.scss',
})
export class LayoutUiComponent {
  @Input() title = '';
  @Input() isLoading = false;

  @Input() previousPageUrl = '';

  @Input() navigationText = 'Back';
}
