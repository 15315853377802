import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mt-button',
  standalone: true,
  templateUrl: './button.ui-component.html',
  styleUrls: ['./button.ui-component.scss'],
  imports: [CommonModule],
})
export class ButtonUiComponent {
  @Input() disabled = false;
  @Input() buttonStyle = 'primary' || 'secondary' || 'link';
  @Input() buttonType = 'button' || 'submit' || 'reset';
  @Input() outline = false;

  @Input() text?: string;

  @Output() submitEvent: EventEmitter<Event> = new EventEmitter();

  onSubmit(event: Event) {
    this.submitEvent.emit(event);
  }
}
