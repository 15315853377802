import { Component, inject, Input, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonUiComponent } from '../button/button.ui-component';
import { IconDownloadUiComponent } from '../icons/icon-download/icon-download.ui-component';

@Component({
  selector: 'mt-button-download',
  standalone: true,
  imports: [CommonModule, ButtonUiComponent, IconDownloadUiComponent],
  templateUrl: './button-download.ui-component.html',
  styleUrl: './button-download.ui-component.scss',
})
export class ButtonDownloadUiComponent {
  @Input() url!: string;
  @Input() name!: string;

  private renderer = inject(Renderer2);

  downloadAsset() {
    fetch(this.url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const link = this.renderer.createElement('a');

        link.href = blobUrl;

        if (this.name) {
          link.download = this.name;
        }

        this.renderer.appendChild(document.body, link);

        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        );

        this.renderer.removeChild(document.body, link);
      });
  }
}
