import {
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
  computed,
  input,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonUiComponent } from '../button/button.ui-component';
import { Pagination } from '@my-toolbox-fe/type-pagination';
import { IconChevronUiComponent } from '../icons/icon-chevron/icon-chevron.ui-component';

@Component({
  selector: 'mt-pagination',
  standalone: true,
  imports: [CommonModule, ButtonUiComponent, IconChevronUiComponent],
  templateUrl: './pagination.ui-component.html',
  styleUrl: './pagination.ui-component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationUiComponent {
  pagination = input.required<Pagination>();
  @Input() offset = 3;
  @Output() pageChange = new EventEmitter<number>();
  @Input() scrollRef: ElementRef | null = null;

  firstPage = signal(0);
  lastPage = computed(() => this.pagination().totalPages - 1);

  showStartEllipsis = signal(false);

  constructor(private cdr: ChangeDetectorRef) {}

  viewAblePagination = computed(() => {
    const { totalPages, currentPage } = this.pagination();

    const startPrevIndex = Math.max(currentPage - this.offset, 0);
    const endNextIndex = Math.min(currentPage + this.offset + 1, totalPages);

    const range = [...Array(totalPages).keys()].slice(startPrevIndex, endNextIndex);

    const isLastPageVisible = !range.includes(this.lastPage());
    const isFirstPageVisible = !range.includes(this.firstPage());

    const showStartEllipsis = startPrevIndex > 0;
    const showEndEllipsis = endNextIndex < totalPages;

    return { range, showStartEllipsis, showEndEllipsis, isLastPageVisible, isFirstPageVisible };
  });

  onSelectPage(index: number) {
    if (this.pagination().currentPage === index) {
      return;
    }
    this.pagination().currentPage = index;
    this.onPageChange();
  }

  onPrevPage() {
    this.pagination().currentPage--;
    this.onPageChange();
  }

  onNextPage() {
    this.pagination().currentPage++;
    this.onPageChange();
  }

  trackByFn(index: number, item: number): number {
    return item;
  }

  onPageChange() {
    this.pageChange.emit(this.pagination().currentPage);
    this.cdr.detectChanges();

    if (this.scrollRef) {
      this.scrollRef?.nativeElement.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'nearest',
      });
    }

    this.cdr.detectChanges();
  }
}
