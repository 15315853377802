@if (title) {
  <div class="top-bar">
    <h4>{{ title }}</h4>
    <ng-content></ng-content>
  </div>
}
<ul>
  @if (items.length) {
    @for (item of items; track $index) {
      @if (!item.hidden) {
        <li>{{ item.label }}</li>
        <li>{{ item.value ? item.value : '-' }}</li>
      }
    }
  } @else {
    <li>No items</li>
    <li></li>
  }
</ul>
