import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  Output,
  Provider,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { Filter } from '@my-toolbox-fe/type-filter';
import { ButtonUiComponent } from '../../button/button.ui-component';
import { TooltipUiComponent } from '../../tooltip/tooltip.ui-component';

export const SELECT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectUiComponent),
  multi: true,
};

@Component({
  selector: 'mt-select',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, ButtonUiComponent, TooltipUiComponent],
  templateUrl: './select.ui-component.html',
  styleUrl: './select.ui-component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [SELECT_CONTROL_VALUE_ACCESSOR],
})
export class SelectUiComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('select') select?: ElementRef;
  @Input() clearable = false;
  @Input() id: string | undefined;
  @Input() label = '';
  @Input() name = '';
  @Input() options?: Filter[] = [{ code: '', value: '', name: '' }];
  @Input() placeholder?: string;
  @Input() required = false;
  @Input() multiple = false;
  @Input() hoist = false;
  @Input() disabled = false;

  @Input() errorMessage = '';

  @Input() hasSelectAll = false;

  @Input() tooltipText = '';

  @Output() selectEvent: EventEmitter<any> = new EventEmitter();
  private renderer = inject(Renderer2);

  private _innerValue: string | string[] = [];

  public get innerValue(): string | string[] {
    return this._innerValue;
  }

  public set innerValue(newValue: string | string[]) {
    this._innerValue = newValue;
    this.onChangeCallback(newValue);
  }

  registerOnChange(callback: (_: string | string[]) => void) {
    this.onChangeCallback = callback;
  }

  public registerOnTouched(callback: () => void) {
    this.onTouchedCallback = callback;
  }

  writeValue(value: string | string[]): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  ngAfterViewInit() {
    this.renderer.listen(this.select?.nativeElement, 'sl-change', (event) => {
      this.innerValue = event.target.value;
      this.filterOptions(event.target.value);
    });
  }

  filterOptions(filterValue: string | string[]) {
    let filteredOptions: any = [];
    if (!this.options) {
      return;
    }

    if (Array.isArray(filterValue) && this.options) {
      filterValue.forEach((item) => {
        const filteredItem = this.options?.find((filterItem: Filter) => filterItem.value === item);
        filteredOptions.push(filteredItem);
      });
      return this.selectEvent.emit(filteredOptions);
    }

    filteredOptions = this.options?.filter(
      (filterItem: Filter) => filterItem.value === filterValue,
    );
    return this.selectEvent.emit(filteredOptions);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChangeCallback: (_: string | string[]) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouchedCallback: () => void = () => {};

  onSelectAll() {
    if (!this.options) {
      return;
    }
    const allValues = this.options?.map((option) => {
      return option.value;
    });

    this.innerValue = allValues;
    this.filterOptions(allValues);
  }
}
