import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mt-card-video',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card-video.ui-component.html',
  styleUrl: './card-video.ui-component.scss',
})
export class CardVideoUiComponent {
  @Input() tag!: string;
  @Input() title!: string;
  @Input() description!: string;
  @Input() videoUrl!: string;
  @Input() mimeType = 'video/webm';
}
