import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mt-upload',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './upload.ui-component.html',
  styleUrl: './upload.ui-component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadUiComponent),
      multi: true,
    },
  ],
})
export class UploadUiComponent implements ControlValueAccessor {
  @ViewChild('preview', { static: true }) preview: ElementRef | null = null;
  @Input() accept: string | undefined;
  @Input() required = false;
  @Input() name = '';
  @Input() maxFileSize = 262144000;
  @Input() errorMessage = 'File is required';

  @Input() message = 'Drag and drop an asset here or choose file';
  isFileSelected = false;
  isDragOver = false;
  previewComponent: HTMLElement | null = null;
  currentFile: File | null = null;

  onChange = (file: File | null) => {
    return file;
  };

  onTouched = () => {
    return;
  };

  writeValue(file: File | null): void {
    this.currentFile = file;
    if (!file) {
      this.currentFile = null;
      this.isFileSelected = false;

      if (this.preview) {
        this.preview.nativeElement.src = '';
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  convertBytesToMegabytes(size: number) {
    return `${(size / (1024 * 1024)).toFixed(2)}MB`;
  }

  fileExtension(filename: string) {
    return filename.split('.').pop()?.toUpperCase();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;

    if (event.dataTransfer && event.dataTransfer.files) {
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.handleFile(files[0]);
      }
    }
  }

  handleFile(file: File) {
    if (!this.preview) return;
    this.preview.nativeElement.src = '';

    if (file.size > this.maxFileSize) {
      this.errorMessage = `File size exceeds the maximum limit of ${this.convertBytesToMegabytes(this.maxFileSize)}.`;
      this.isFileSelected = false;
      this.currentFile = null;
      this.onChange(null);
      this.onTouched();
      return;
    }

    this.currentFile = file;
    this.isFileSelected = true;

    this.onChange(this.currentFile);
    this.onTouched();

    if (!this.currentFile?.type.match(/^image\//)) return;

    this.preview.nativeElement.src = URL.createObjectURL(this.currentFile);
  }

  onFileChange(event: Event) {
    const element = event.target as HTMLInputElement;
    const files = element.files;

    if (!files || files.length === 0) return;

    if (!this.preview) return;

    this.handleFile(files[0]);

    element.value = '';
  }

  onFileRemove() {
    if (!this.preview) return;

    this.preview.nativeElement.src = '';
    if (this.currentFile) {
      this.isFileSelected = false;
      this.onChange(null);
      this.onTouched();
      this.errorMessage = 'There is no file submitted';
    }
  }
}
