import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'mt-error',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './error.ui-component.html',
  styleUrl: './error.ui-component.scss',
})
export class ErrorUiComponent {
  @Input() message!: string;

  @Input() title!: string;
}
