<div class="loader">
  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="100"
      cy="100"
      fill="none"
      r="0"
      stroke="hsl(188.5, 89.5%, 18.6%)"
      stroke-opacity="1"
      stroke-width=".5"
    >
      <animate
        attributeName="r"
        calcMode="spline"
        dur="2"
        keySplines="0 .2 .5 1"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;80"
      ></animate>
      <animate
        attributeName="stroke-width"
        calcMode="spline"
        dur="2"
        keySplines="0 .2 .5 1"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="0;25"
      ></animate>
      <animate
        attributeName="stroke-opacity"
        calcMode="spline"
        dur="2"
        keySplines="0 .2 .5 1"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      ></animate>
    </circle>
  </svg>
</div>
