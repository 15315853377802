<svg width="17" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M15.597 8.475A7.79 7.79 0 0 0 8.625 3.5a7.789 7.789 0 0 0-6.972 4.975.467.467 0 0 0 0 .317 7.788 7.788 0 0 0 6.972 4.975 7.789 7.789 0 0 0 6.972-4.975.466.466 0 0 0 0-.317Zm-6.972 4.359c-2.473 0-5.087-1.835-6.034-4.2.947-2.367 3.56-4.2 6.034-4.2 2.473 0 5.087 1.833 6.034 4.2-.947 2.365-3.56 4.2-6.034 4.2Z"
    fill="currentColor"
  />
  <path
    d="M8.625 5.833a2.8 2.8 0 1 0 0 5.6 2.8 2.8 0 0 0 0-5.6Zm0 4.667a1.867 1.867 0 1 1 0-3.734 1.867 1.867 0 0 1 0 3.734Z"
    fill="currentColor"
  />
</svg>
