import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mt-icon-download',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon-download.ui-component.html',
  styleUrl: './icon-download.ui-component.scss',
})
export class IconDownloadUiComponent {}
