<sl-select
  #select
  [clearable]="clearable"
  [disabled]="disabled"
  [hoist]="hoist"
  [id]="id"
  [label]="label"
  [multiple]="multiple"
  [name]="name"
  [placeholder]="placeholder"
  [required]="required"
  [value]="innerValue"
>
  @for (option of options; track option?.value) {
    <sl-option [value]="option?.value">{{ option.name }}</sl-option>
  } @empty {
    <sl-option>There are no items.</sl-option>
  }
</sl-select>
@if (hasSelectAll) {
  <mt-button buttonType="button" (submitEvent)="onSelectAll()">Select all</mt-button>
}
<span class="form-error-message">
  @if (errorMessage) {
    {{ errorMessage }}
  } @else {
    {{ label }} is required
  }
</span>
@if (tooltipText) {
  <mt-tooltip [content]="tooltipText"></mt-tooltip>
}
