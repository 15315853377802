<label>
  <span class="label">{{ label }}{{ required ? '*' : '' }}</span>
  <textarea
    (blur)="onBlur()"
    [(ngModel)]="innerValue"
    [name]="name"
    [required]="required"
    [rows]="rows"
    [placeholder]="placeholder"
  >
  </textarea>

  <span class="form-error-message">{{ label }} is required</span>
</label>
