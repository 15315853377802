@if(isLoading) {
<mt-loader />
}

<table>
  <thead>
    <tr>
      @for(column of mappedColumns; track $index) { @if(column.sortAble) {
      <th class="sortable" (click)="onSort(column)">
        <div class="sort">
          <div>{{column.label}}</div>
          <img
            class="sort-icon"
            [class.sorted]="column.sortDirection === 'desc'"
            src="./assets/icons/arrow.svg"
            alt="sort icon"
          />
        </div>
      </th>
      } @else {
      <th>{{column.label}}</th>
      } } @if(actions) {
      <th>Actions</th>
      }
    </tr>
  </thead>

  <tbody>
    @if(rowData.length) { @for ( row of rowData; track $index) {
    <tr>
      <ng-container
        *ngTemplateOutlet="
                rows || null;
                context: { $implicit: row, i:$index }
                "
      ></ng-container>
      @if(actions){
      <td>
        <div class="actions">
          <ng-container
            *ngTemplateOutlet="actions || null; context: {$implicit: row}"
          ></ng-container>
        </div>
      </td>
      }
    </tr>
    } }@else {
    <td colspan="100%" class="empty">No items found</td>
    }
  </tbody>
</table>
