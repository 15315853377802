import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mt-skeleton',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './skeleton.ui-component.html',
  styleUrl: './skeleton.ui-component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SkeletonUiComponent {
  @Input() effect: 'none' | 'sheen' | 'pulse' = 'pulse';
}
