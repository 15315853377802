<label
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
  [class.dragover]="isDragOver"
>
  <input
    (change)="onFileChange($event)"
    [name]="name"
    [accept]="accept"
    [required]="required"
    class="input"
    type="file"
    hidden
  />
  <svg class="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
    <path
      fill="#09C"
      fill-rule="evenodd"
      d="m6 18 1.4 1.4 7.6-7.6V30h2V11.8l7.6 7.6L26 18 16 8 6 18ZM6 8V4h20v4h2V4c0-1.2-.8-2-2-2H6c-1.2 0-2 .8-2 2v4h2Z"
      clip-rule="evenodd"
    />
  </svg>
  <p>{{ message }}</p>
</label>
<span class="form-error-message">{{ errorMessage }}</span>

<div [class.preview--enabled]="isFileSelected" class="preview">
  <img class="thumbnail" alt="file thumbnail" #preview />
  <ul class="file-list">
    <li class="file-name">
      {{ currentFile ? currentFile.name : 'No file selected' }}
    </li>
    <li>
      {{ currentFile ? fileExtension(currentFile.name) : '' }},
      {{ currentFile ? convertBytesToMegabytes(currentFile.size) : '' }}
    </li>
    <li>
      <button type="button" class="remove" (click)="onFileRemove()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path
            fill="#0078A1"
            fill-rule="evenodd"
            d="M7 0C3.1 0 0 3.1 0 7s3.1 7 7 7 7-3.1 7-7-3.1-7-7-7Zm2.7 10.5L7 7.8l-2.7 2.7-.8-.8L6.2 7 3.5 4.3l.8-.8L7 6.2l2.7-2.7.8.8L7.8 7l2.7 2.7-.8.8Z"
            clip-rule="evenodd"
          />
        </svg>
        <span>Remove</span>
      </button>
    </li>
  </ul>
</div>
