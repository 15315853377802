import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mt-icon-share',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon-share.ui-component.html',
  styleUrl: './icon-share.ui-component.scss',
})
export class IconShareUiComponent {}
