import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mt-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './details.ui-component.html',
  styleUrl: './details.ui-component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class DetailsUiComponent {
  @Input() summary = '';
  @Input() open = false;
}
