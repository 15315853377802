import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

import { LoaderUiComponent } from '../loader/loader.ui-component';
import { CommonModule } from '@angular/common';

export type ColumnData = {
  key: string;
  sortAble?: boolean;
  label: string;
  sortDirection?: string;
};

@Component({
  selector: 'mt-table',
  standalone: true,
  imports: [CommonModule, LoaderUiComponent],
  templateUrl: './table.ui-component.html',
  styleUrl: './table.ui-component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class TableUiComponent implements OnInit {
  @Input() isLoading = false;
  @Input() rowData!: any[];
  @Input() columnData!: ColumnData[];
  @Input() sortDirection: boolean | null = null;

  @ContentChild('rows') rows:
    | TemplateRef<{
        $implicit: { [index: string]: string | string[] };
        i: number;
      }>
    | undefined;
  @ContentChild('actions') actions:
    | TemplateRef<{ $implicit: { [index: string]: string | string[] } }>
    | undefined;
  @Output() sortEvent: EventEmitter<{ key: string; sortDirection: string }> =
    new EventEmitter();

  mappedColumns: ColumnData[] | null = null;

  ngOnInit(): void {
    this.mappedColumns = this.columnData.map((column) => {
      return {
        ...column,
        sortDirection: 'asc',
      };
    });
  }

  onSort(column: ColumnData) {
    column.sortDirection = column.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortEvent.emit({
      key: column.key,
      sortDirection: column.sortDirection,
    });
  }
}
