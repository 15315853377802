import { Component, forwardRef, Input, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

export const TEXTAREA_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextareaUiComponent),
  multi: true,
};

@Component({
  selector: 'mt-textarea',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './textarea.ui-component.html',
  styleUrl: './textarea.ui-component.scss',
  providers: [TEXTAREA_CONTROL_VALUE_ACCESSOR],
})
export class TextareaUiComponent implements ControlValueAccessor {
  @Input() name = '';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() rows = 5;

  @Input() label = '';
  private _innerValue = '';

  public get innerValue(): string {
    return this._innerValue;
  }

  public set innerValue(newValue: string) {
    this._innerValue = newValue;
    this.onChangeCallback(newValue);
  }

  public registerOnChange(callback: (_: string) => void) {
    this.onChangeCallback = callback;
  }

  public registerOnTouched(callback: () => void) {
    this.onTouchedCallback = callback;
  }

  writeValue(value: string): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  public onBlur() {
    this.onTouchedCallback();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChangeCallback: (_: string) => void = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouchedCallback: () => void = () => {};
}
