import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Summary } from '@my-toolbox-fe/type-summary';

@Component({
  selector: 'mt-summary',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './summary.ui-component.html',
  styleUrl: './summary.ui-component.scss',
})
export class SummaryUiComponent {
  @Input() items!: Summary[];

  @Input() title = '';
}
