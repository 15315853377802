import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mt-asset-preview',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './asset-preview.ui-component.html',
  styleUrl: './asset-preview.ui-component.scss',
})
export class AssetPreviewUiComponent {
  @Input() file!: {
    name: string;
    size: string;
    type: string;
    url: string;
    extension: string;
  };
}
