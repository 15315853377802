import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'mt-toggle',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './toggle.ui-component.html',
  styleUrl: './toggle.ui-component.scss',
})
export class ToggleUiComponent {
  @Input() label: string = '';
  @Input() selected: boolean = false;

  @Output() checked = new EventEmitter();

  isSelected($event: any) {
    this.checked.emit($event);
  }
}
