<label>
  <span class="label">{{label}}{{required ? '*' : ''}}</span>
  <input
    (blur)="onBlur()"
    [(ngModel)]="innerValue"
    [name]="name"
    [placeholder]="placeholder"
    [required]="required"
    type="date"
    [min]="min"
  />
  @if(required){<span class="form-error-message"
    >@if(errorMessage) { {{errorMessage}} } @else { {{name}} is required} </span
  >}
</label>
