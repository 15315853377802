import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  forwardRef,
  inject,
  Input,
  Provider,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CHECKBOX_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxUiComponent),
  multi: true,
};

@Component({
  selector: 'mt-checkbox',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './checkbox.ui-component.html',
  styleUrl: './checkbox.ui-component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CHECKBOX_CONTROL_VALUE_ACCESSOR],
})
export class CheckboxUiComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('checkbox') checkbox?: ElementRef;

  @Input() name = '';

  @Input() disabled = false;
  private renderer = inject(Renderer2);

  private _innerValue = false;

  public get innerValue(): boolean {
    return this._innerValue;
  }

  public set innerValue(newValue: boolean) {
    this._innerValue = newValue;
    this.onChangeCallback(newValue);
  }

  registerOnChange(callback: (_: boolean) => void) {
    this.onChangeCallback = callback;
  }

  registerOnTouched(callback: () => void): void {
    this.onTouchedCallback = callback;
  }

  writeValue(value: boolean): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  ngAfterViewInit(): void {
    this.renderer.listen(this.checkbox?.nativeElement, 'sl-change', (event) => {
      this.innerValue = event.target.checked;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChangeCallback: (_: boolean) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouchedCallback: () => void = () => {};
}
