import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { DialogService } from './dialog.service';
import { CommonModule } from '@angular/common';
import { CloseComponent } from '../close/close.component';

@Component({
  selector: 'mt-dialog',
  template: `
    <dialog #dialog>
      <mt-close (closeEvent)="closeDialog()"></mt-close>
      @if (title) {
        <section>
          <h2 class="dialog-title">{{ title }}</h2>
        </section>
      }
      <section>
        <ng-content></ng-content>
      </section>
    </dialog>
  `,
  styleUrls: ['./dialog.ui-component.scss'],
  standalone: true,
  imports: [CommonModule, CloseComponent],
})
export class DialogUiComponent implements AfterViewInit, OnChanges {
  @ViewChild('dialog') dialog?: ElementRef;

  @Input() closeIcon = true;
  @Input() title?: string;
  @Input() text: string | undefined;
  @Input() openModal: boolean | undefined;

  @Output() dialogResult = new EventEmitter();

  constructor(protected dialogService: DialogService) {}

  ngAfterViewInit() {
    this.dialog?.nativeElement.showModal();
  }

  ngOnChanges() {
    if (this.openModal) {
      this.dialog?.nativeElement.showModal();
    }
  }

  closeDialog() {
    this.dialog?.nativeElement.close();
    this.dialogService.close();

    this.dialogResult.emit('close');
  }
}
