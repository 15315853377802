<div (click)="closeEventHandler($event)" class="close">
  close
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3143 11L8 8.68571L5.68571 11L5 10.3143L7.31429 8L5 5.68571L5.68571 5L8 7.31429L10.3143 5L11 5.68571L8.68571 8L11 10.3143L10.3143 11Z"
      fill="black"
    />
    <path
      d="M8 15C6.61553 15 5.26215 14.5895 4.11101 13.8203C2.95987 13.0511 2.06266 11.9579 1.53285 10.6788C1.00303 9.3997 0.86441 7.99224 1.13451 6.63437C1.4046 5.2765 2.07129 4.02922 3.05026 3.05026C4.02922 2.07129 5.2765 1.4046 6.63437 1.13451C7.99224 0.86441 9.3997 1.00303 10.6788 1.53285C11.9579 2.06266 13.0511 2.95987 13.8203 4.11101C14.5895 5.26215 15 6.61553 15 8C14.9979 9.85588 14.2597 11.6351 12.9474 12.9474C11.6351 14.2597 9.85588 14.9979 8 15ZM8 2C6.81331 2 5.65328 2.3519 4.66658 3.01118C3.67989 3.67047 2.91085 4.60755 2.45673 5.7039C2.0026 6.80026 1.88378 8.00666 2.11529 9.17054C2.3468 10.3344 2.91825 11.4035 3.75736 12.2426C4.59648 13.0818 5.66557 13.6532 6.82946 13.8847C7.99335 14.1162 9.19975 13.9974 10.2961 13.5433C11.3925 13.0892 12.3295 12.3201 12.9888 11.3334C13.6481 10.3467 14 9.18669 14 8C13.9982 6.40926 13.3655 4.88419 12.2406 3.75936C11.1158 2.63454 9.59074 2.00182 8 2Z"
      fill="black"
    />
  </svg>
</div>
