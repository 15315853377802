<mt-input
  [label]="label"
  [(ngModel)]="mainInput"
  (ngModelChange)="onMainInputChange()"
></mt-input>
<mt-button (submitEvent)="addInput()">+</mt-button>

<div class="inputs">
  <ng-template #container> </ng-template>
</div>
