import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EnvironmentInjector,
  Injectable,
} from '@angular/core';
import { DialogUiComponent } from './dialog.ui-component';

@Injectable({ providedIn: 'root' })
export class DialogService {
  dialogRef?: ComponentRef<DialogUiComponent>;

  constructor(
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector,
  ) {}

  open(component: any, options?: any) {
    // Create a `ComponentRef` instance.
    this.dialogRef = createComponent(component, {
      environmentInjector: this.injector,
    });

    if (options) {
      Object.entries(options).map(([key, value]) => {
        this.dialogRef?.setInput(key, value);
      });
    }

    document.body.appendChild(this.dialogRef.location.nativeElement);
    document.body.style.overflowY = 'hidden';

    // Register the newly created ref using the `ApplicationRef` instance
    // to include the component view into change detection cycles.
    this.appRef.attachView(this.dialogRef.hostView);
  }

  close() {
    if (this.dialogRef) {
      this.appRef.detachView(this.dialogRef.hostView);
      document.body.style.overflowY = 'auto';
    }
  }
}
