import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mt-close',
  templateUrl: './close.component.html',
  styleUrls: ['./close.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class CloseComponent {
  @Output() closeEvent = new EventEmitter();

  closeEventHandler(event: Event) {
    this.closeEvent.emit(event);
  }
}
