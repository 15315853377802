import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

type LinkStyle = 'default' | 'button';

@Component({
  selector: 'mt-link',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './link.ui-component.html',
  styleUrl: './link.ui-component.scss',
})
export class LinkUiComponent {
  @Input() link!: string | string[];
  @Input() target?: string;
  @Input() icon?: string;
  @Input() linkStyle: LinkStyle = 'default';
}
