<label>
  <span class="label">{{ label }}{{ required ? '*' : '' }} </span>
  <input
    (blur)="onBlur()"
    [(ngModel)]="innerValue"
    [name]="name"
    [placeholder]="placeholder"
    [required]="required"
    [maxlength]="maxlength"
    [minlength]="minlength"
    type="text"
  />

  <span class="form-error-message">{{ label }} is required </span>
</label>
@if (tooltipText) {
  <mt-tooltip [content]="tooltipText"></mt-tooltip>
}
