import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

type AlertVariant = 'primary' | 'success' | 'info' | 'warning' | 'danger';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  open(options: {
    message: string;
    variant?: AlertVariant;
    duration?: number;
  }) {
    const alertElement = this.renderer.createElement('sl-alert');

    this.renderer.setProperty(alertElement, 'innerText', options.message);

    if (options.variant) {
      this.renderer.setAttribute(alertElement, 'variant', options.variant);
    }

    const duration = options.duration ? options.duration.toString() : '3000';
    this.renderer.setAttribute(alertElement, 'duration', duration);

    this.renderer.appendChild(document.body, alertElement);

    alertElement.toast();
  }
}
