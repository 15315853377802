import { LogLevel } from '@azure/msal-browser';

export const environment = {
  production: false,
  environment: 'development',
  categoryEnabled: false,
  inactivityTimeout: 60,
  baseUrl: 'https://mytoolbox-backend-dev.atlascopco-powertechnique.com/toolbox/v1',
  msalConfig: {
    auth: {
      clientId: 'e399885b-f12e-45fa-a92c-44c07ef5465b',
      authority: 'https://login.microsoftonline.com/556e6b1f-b49d-4278-8baf-db06eeefc8e9',
    },
  },
  apiConfig: {
    scopes: ['openid', 'profile'],
    uri: window.location.origin + '/',
  },
  msalLogLevel: LogLevel.Info,
};
