import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  forwardRef,
  inject,
  Input,
  Provider,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const RADIO_GROUP_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioGroupUiComponent),
  multi: true,
};

@Component({
  selector: 'mt-radio-group',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './radio-group.ui-component.html',
  styleUrl: './radio-group.ui-component.scss',
  providers: [RADIO_GROUP_CONTROL_VALUE_ACCESSOR],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class RadioGroupUiComponent
  implements ControlValueAccessor, AfterViewInit
{
  @ViewChild('radioGroup') radioGroup?: ElementRef;

  @Input() name = '';
  @Input() value = '';
  private renderer = inject(Renderer2);

  private _innerValue = '';

  public get innerValue(): string {
    return this._innerValue;
  }

  public set innerValue(newValue: string) {
    this._innerValue = newValue;
    this.onChangeCallback(newValue);
  }

  registerOnChange(callback: (_: string) => void) {
    this.onChangeCallback = callback;
  }

  registerOnTouched(callback: () => void): void {
    this.onTouchedCallback = callback;
  }

  writeValue(value: string): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  ngAfterViewInit(): void {
    this.renderer.listen(
      this.radioGroup?.nativeElement,
      'sl-change',
      (event) => {
        this.innerValue = event.target.value;
      },
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChangeCallback: (_: string) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouchedCallback: () => void = () => {};
}
