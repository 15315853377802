import { Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'mt-card',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './card.ui-component.html',
  styleUrl: './card.ui-component.scss',
})
export class CardUiComponent {
  @Input() image = '';
  @Input() title = '';
  @Input() text = '';
  @Input() alt = '';
}
